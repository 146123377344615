import * as React from "react";

import { AccommodationType, Resort, Unit, getMxtsEnv } from "../../mxts";
import { Resort as MxtsResort, Resource as MxtsResource, Unit as MxtsUnit } from "@maxxton/cms-mxts-api";
import { ResultsPanelImageGalleryContainer, ResultsPanelImageGalleryContainerProps, ResultsPanelImageGalleryContainerState } from "./ResultsPanelImageGalleryContainer";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { ConfiguredLink } from "../../../utils/linking.util";
import { ContentType } from "../../../components/components.enum";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { ReservationContainerCRPProps } from "../../dynamic/reservation/container/reservationContainer.types";
import { WidgetOptions } from "./";
import { getSelectedAccoTypeReservedResource } from "../../../redux/reducers/myEnv/myEnv.util";

interface ImageGalleryProps extends ReservationContainerCRPProps {
    options: WidgetOptions;
    context: CMSProvidedProperties;
    accommodationType?: AccommodationType;
    specialClass?: string;
    unit?: Unit;
    dynamicFilter?: DynamicFilter;
    amenityCodes?: string[];
    resort?: Resort;
    className?: string;
    link: ConfiguredLink;
    warmupState?: ResultsPanelImageGalleryContainerState;
    activity?: Activity;
}

const getImageManagerIdsFromEsReservation = async (props: ImageGalleryProps): Promise<number[]> => {
    const { context, esReservationResult } = props;
    const { contentTypes } = props.options;
    const accoTypeReservedResource = getSelectedAccoTypeReservedResource(esReservationResult);
    const imageManagerIds: Array<number | undefined> = [];
    const env = await getMxtsEnv(context, context.currentLocale.code);
    if (!contentTypes?.length || contentTypes?.some((contentType) => contentType.value === ContentType.UNIT)) {
        const unit: MxtsUnit | null = await DomainObjectUtil.getUnitById(context.mxtsApi, accoTypeReservedResource?.unit?.unitId || accoTypeReservedResource?.unitId, env);
        imageManagerIds.push(unit?.imageManagerId);
    }
    if (!contentTypes?.length || contentTypes?.some((contentType) => contentType.value === ContentType.ACCOMMODATION_TYPE)) {
        if (accoTypeReservedResource?.resource) {
            imageManagerIds.push(accoTypeReservedResource?.resource?.imageManagerId);
        } else {
            const resource: MxtsResource | null = await DomainObjectUtil.getResourceById(context.mxtsApi, accoTypeReservedResource?.resourceId, env);
            imageManagerIds.push(resource?.imageManagerId);
        }
    }
    if (!contentTypes?.length || contentTypes?.some((contentType) => contentType.value === ContentType.RESORT)) {
        const resortId = esReservationResult?.resort?.resortId || esReservationResult?.reservation.resortId;
        const resort: MxtsResort | undefined | null = resortId ? await DomainObjectUtil.getResort(context.mxtsApi, { resortIds: [resortId] }, env) : undefined;
        imageManagerIds.push(resort?.imageManagerId);
    }
    return imageManagerIds.filter((imageManagerId) => imageManagerId) as number[];
};

export const ResultsPanelImageWidget = (props: ImageGalleryProps) => {
    const [imageManagerIds, setImageManagerIds] = React.useState<number[] | undefined>(undefined);
    React.useEffect(() => {
        if (props.esReservationResult && !props.activity) {
            getImageManagerIdsFromEsReservation(props).then((imageManagerIds: number[]) => setImageManagerIds(imageManagerIds));
        }
    }, [props]);

    if (props.resort) {
        return (
            <ResultsPanelImageGalleryContainer
                imageManagerId={props.resort.imageManagerId}
                context={props.context}
                specialClass={""}
                options={props.options}
                dynamicFilter={props.dynamicFilter}
                amenityCodes={props.amenityCodes}
                resort={props.resort}
                className={props.className}
                link={props.link}
                warmupState={props.warmupState}
            />
        );
    } else if (props.accommodationType) {
        return (
            <ResultsPanelImageGalleryContainer
                accommodationType={props.accommodationType}
                imageManagerId={props.accommodationType.imageManagerId}
                context={props.context}
                specialClass={props.accommodationType!.specialId ? "image-special-label" : ""}
                options={props.options}
                amenityCodes={props.amenityCodes}
                className={props.className}
                link={props.link}
                warmupState={props.warmupState}
            />
        );
    } else if (props.unit) {
        return (
            <ResultsPanelImageGalleryContainer
                imageManagerId={props.unit.imageManagerId}
                context={props.context}
                specialClass={""}
                options={props.options}
                unit={props.unit}
                dynamicFilter={props.dynamicFilter}
                amenityCodes={props.amenityCodes}
                className={props.className}
                link={props.link}
                warmupState={props.warmupState}
            />
        );
    } else if (imageManagerIds?.length) {
        return (
            <ResultsPanelImageGalleryContainer
                imageManagerId={imageManagerIds[0]}
                imageManagerIds={imageManagerIds}
                context={props.context}
                specialClass={""}
                options={props.options}
                className={props.className}
                link={props.link}
                warmupState={props.warmupState}
            />
        );
    } else if (props.activity) {
        const resourceActivity = props.activity.resourceActivity;
        return (
            <ResultsPanelImageGalleryContainer
                imageManagerId={props.activity.showMainActivity ? resourceActivity.resortActivity.imageManagerId : resourceActivity.imageManagerId}
                context={props.context}
                specialClass={""}
                options={props.options}
                activity={props.activity}
                dynamicFilter={props.dynamicFilter}
                amenityCodes={props.amenityCodes}
                className={props.className}
                link={props.link}
                warmupState={props.warmupState}
            />
        );
    }
    return null;
};

export async function warmupCache(props: ImageGalleryProps): Promise<ResultsPanelImageGalleryContainerState | undefined> {
    let imageManagerIds: number[] | undefined;
    if (props.esReservationResult) {
        imageManagerIds = await getImageManagerIdsFromEsReservation(props);
    }
    let containerProps: ResultsPanelImageGalleryContainerProps = {
        imageManagerId: 0,
        context: props.context,
        specialClass: "",
        options: props.options,
        link: props.link,
        className: props.className,
    };

    if (props.resort) {
        containerProps = {
            ...containerProps,
            imageManagerId: props.resort.imageManagerId,
            dynamicFilter: props.dynamicFilter,
            amenityCodes: props.amenityCodes,
        };
    } else if (props.accommodationType) {
        containerProps = {
            ...containerProps,
            accommodationType: props.accommodationType,
            imageManagerId: props.accommodationType.imageManagerId,
            specialClass: props.accommodationType!.specialId ? "image-special-label" : "",
            amenityCodes: props.amenityCodes,
        };
    } else if (props.unit) {
        containerProps = {
            ...containerProps,
            unit: props.unit,
            imageManagerId: props.unit.imageManagerId,
            dynamicFilter: props.dynamicFilter,
            amenityCodes: props.amenityCodes,
        };
    } else if (imageManagerIds?.length) {
        containerProps = {
            ...containerProps,
            imageManagerId: imageManagerIds[0],
            imageManagerIds,
        };
    } else {
        return;
    }
    return ResultsPanelImageGalleryContainer.warmupCache(containerProps);
}
