import * as Icons from "../../page/icons";
import * as React from "react";

import { FormSpec, getHelpId, localized, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { LocalizedTitleOptions, getWidgetTitleOptions, titleStylingOptions } from "../../../components/widgetTitleAndLabel/localizedLableTitle.util";
import { ResultsPanelImageWidget, warmupCache } from "./ResultsPanelImageWidget";
import { getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";

import { NumberMultiSelectOption } from "../../mxts/selectOption.types";
import { TypesearchContainerWidgetSpec } from "../../";
import { WidgetGroup } from "../../widget.enum";
import { contentTypeMultiSelector } from "../../../components/utils";
import dynamicImageSizeAutocomplete from "../../../inputSpecs/dynamicImageSizeAutocomplete";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface LocalizedOptions {
    locale: string;
    content: string;
    modalTitle: string | null;
    optionalText: string | null;
    showAllPhotos: string;
    optionalButtonText: string | null;
}

export interface WidgetOptions extends LocalizedTitleOptions {
    contentTypes?: NumberMultiSelectOption[];
    styleIds: string[];
    imageGalleryType: string;
    showFigCaption: boolean;
    useAsBackground: boolean;
    showThumbnailOnModal: boolean;
    showSliderInsideImage: boolean;
    showSliderInsideImageIndicators: boolean;
    maxAmountSlideshow: number;
    showOptionalIconText: boolean;
    showOptionalIcon: string;
    optionalIcon: string;
    showOptionalText: string;
    showModalTitle: boolean;
    placementOfMultipleImages: string;
    localized: LocalizedOptions[];
    enableBookingsEngineLink: boolean;
    sliderName: string;
    imageActionType: string;
    displayButton: boolean;
    hideOverlay: boolean;
    hideSpecialLabel: boolean;
    linking: LinkingSpecOptions;
    amountOfIndicators: number;
    removeHoverEffect: boolean;
    changeDynamicImageSize?: boolean;
    dynamicImageSize?: string;
}

enum amountOfIndicators {
    FIVE = "5",
    SEVEN = "7",
}

const TARGETS = ["results-panel-image-gallery"];

const iconList = Object.keys(Icons.default).map((key: any) => ({ label: (Icons.default as any)[key], value: key }));

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "results-panel-image-gallery-options",
    name: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "image"),
    pluralName: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "images"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                variable: "imageGalleryType",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "imageGalleryType"),
                                type: "select",
                                default: "single",
                                optionList: [
                                    {
                                        value: "single",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "single"),
                                    },
                                    {
                                        value: "multiple",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "multiple"),
                                    },
                                ],
                            },
                            {
                                variable: "placementOfMultipleImages",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "placementOfMultipleImages"),
                                type: "radioImage",
                                default: "right",
                                visible: (options: WidgetOptions) => options.imageGalleryType === "multiple",
                                radioOptions: [
                                    {
                                        value: "right",
                                        label: getI18nLocaleObject(namespaceList.admin, "right"),
                                        element: (
                                            <svg className="right" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="0" width="60%" height="80" />
                                                <rect fill="rgba(214,208,208,0.5)" x="62%" y="0" width="38%" height="46%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="62%" y="52%" width="38%" height="48%" />
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "left",
                                        label: getI18nLocaleObject(namespaceList.admin, "left"),
                                        element: (
                                            <svg className="left" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="40%" y="0" width="60%" height="100%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="0" width="38%" height="46%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="52%" width="38%" height="48%" />
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "top",
                                        label: getI18nLocaleObject(namespaceList.admin, "top"),
                                        element: (
                                            <svg className="top" width="100%" height="80">
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="0" width="49%" height="46%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="51%" y="0" width="49%" height="46%" />
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="52%" width="100%" height="48%" />
                                            </svg>
                                        ),
                                    },
                                    {
                                        value: "bottom",
                                        label: getI18nLocaleObject(namespaceList.admin, "bottom"),
                                        element: (
                                            <svg className="bottom" width="100%" height="80">
                                                <rect fill="rgba(153,153,153,0.5)" x="0" y="0" width="100%" height="48%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="0" y="52%" width="49%" height="46%" />
                                                <rect fill="rgba(214,208,208,0.5)" x="51%" y="52%" width="49%" height="46%" />
                                            </svg>
                                        ),
                                    },
                                ],
                            },
                            {
                                variable: "showFigCaption",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showFigCaption"),
                                type: "checkbox",
                            },
                            {
                                variable: "imageActionType",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "imageActionType"),
                                type: "select",
                                default: "noAction",
                                optionList: [
                                    {
                                        value: "noAction",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "noAction"),
                                    },
                                    {
                                        value: "showPopup",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showPopup"),
                                    },
                                    {
                                        value: "redirectLink",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "redirectLink"),
                                    },
                                ],
                            },
                            contentTypeMultiSelector("contentTypes", true, false),
                            ...getWidgetTitleOptions<WidgetOptions>(),
                            ...dynamicImageSizeAutocomplete("changeDynamicImageSize", "dynamicImageSize"),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    help: getHelpId("XxZVZO-styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            ...titleStylingOptions<WidgetOptions>(),
                            {
                                variable: "useAsBackground",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "useAsBackground"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.imageGalleryType === "single",
                            },
                            {
                                variable: "hideSpecialLabel",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "hideSpecialLabel"),
                                type: "checkbox",
                            },
                            {
                                variable: "displayButton",
                                label: getI18nLocaleObject(namespaceList.admin, "displayButton"),
                                type: "checkbox",
                            },
                            // jscpd:ignore-start
                            localized({
                                variable: "localized",
                                visible: (item: WidgetOptions) => item.displayButton,
                                tabContent: [
                                    {
                                        variable: "optionalButtonText",
                                        label: getI18nLocaleObject(namespaceList.widgetImageGallery, "optionalButtonText"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "hideOverlay",
                                label: getI18nLocaleObject(namespaceList.admin, "hideOverlay"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.displayButton,
                            },
                            {
                                variable: "removeHoverEffect",
                                label: getI18nLocaleObject(namespaceList.admin, "removeHoverEffect"),
                                type: "checkbox",
                            },
                            // jscpd:ignore-end
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "slider"),
                    visible: (options: WidgetOptions) => options.imageGalleryType === "single",
                    properties: [
                        [
                            {
                                variable: "showSliderInsideImage",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showSliderInsideImage"),
                                visible: (options: WidgetOptions) => options.imageGalleryType === "single",
                                type: "checkbox",
                            },
                            {
                                variable: "showSliderInsideImageIndicators",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showSliderInsideImageIndicators"),
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.showSliderInsideImage,
                            },
                            {
                                variable: "amountOfIndicators",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "amountOfIndicators"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.showSliderInsideImageIndicators,
                                optionList: [
                                    {
                                        value: 5,
                                        label: amountOfIndicators.FIVE,
                                    },
                                    {
                                        value: 7,
                                        label: amountOfIndicators.SEVEN,
                                    },
                                ],
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "popup"),
                    visible: (options: WidgetOptions) => options.imageActionType === "showPopup",
                    properties: [
                        [
                            {
                                variable: "showThumbnailOnModal",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showThumbnailOnModal"),
                                type: "checkbox",
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "customTitleExtra"),
                                type: "paragraph",
                            },
                            {
                                variable: "showModalTitle",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showModalTitle"),
                                type: "checkbox",
                            },
                            localized({
                                variable: "localized",
                                visible: (item: WidgetOptions) => item.imageActionType === "showPopup" && item.showModalTitle,
                                tabContent: [
                                    {
                                        variable: "modalTitle",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "modalTitle"),
                                        type: "text",
                                    },
                                ],
                            }),
                            {
                                variable: "showOptionalIconText",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showOptionalIconText"),
                                type: "checkbox",
                            },
                            {
                                variable: "optionalIcon",
                                label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "showOptionalIcon"),
                                type: "select",
                                visible: (item: WidgetOptions) => item.showOptionalIconText,
                                optionList: iconList,
                            },
                            localized({
                                variable: "localized",
                                visible: (item: WidgetOptions) => item.showOptionalIconText,
                                tabContent: [
                                    {
                                        variable: "optionalText",
                                        label: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "optionalText"),
                                        type: "text",
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    visible: (options: WidgetOptions) => options.imageActionType === "redirectLink",
                    properties: [[linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" })]],
                },
            ],
        },
    ],
};

export const resultsPanelImageGalleryWidget: TypesearchContainerWidgetSpec<WidgetOptions> = {
    id: "resultsPanelImageGalleryWidget",
    type: "resultsPanel",
    widgetGroup: WidgetGroup ? WidgetGroup.CONTENT : 2,
    name: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "resultsPanelImageGalleryWidget"),
    description: getI18nLocaleObject(namespaceList.widgetResultsImageGallery, "resultsPanelImageGalleryWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        imageGalleryType: "single",
        showFigCaption: false,
        useAsBackground: false,
        showThumbnailOnModal: false,
        showSliderInsideImage: false,
        showSliderInsideImageIndicators: false,
        maxAmountSlideshow: 3,
        showOptionalIconText: false,
        showOptionalIcon: "",
        optionalIcon: "",
        showOptionalText: "",
        showModalTitle: false,
        placementOfMultipleImages: "right",
        localized: [],
        enableBookingsEngineLink: false,
        sliderName: "",
        imageActionType: "noAction",
        displayButton: false,
        hideOverlay: false,
        hideSpecialLabel: false,
        linking: linkingSpecDefaultValues,
        amountOfIndicators: 7,
        removeHoverEffect: false,
    }),
    container: false,
    async render(widget, context, sitemapPageLinkWidgetOptions, resultOptions) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const link = await getLinkFromLinkingSpec({ linkingSpecOptions: widget.options.linking, context });
        let warmupState;
        if (isServerSide() && (!resultOptions || Object.keys(resultOptions).length > 0)) {
            warmupState = await warmupCache({
                options: widget.options,
                context,
                className: styleClasses,
                link,
                ...resultOptions,
            });
        }
        return <ResultsPanelImageWidget options={widget.options} context={context} className={styleClasses} link={link} warmupState={warmupState} />;
    },
};
