import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";

import { AccommodationType, Unit, getMxtsEnv } from "../../mxts";
import { ResultsPanelImageGallery, ResultsPanelImageGalleryBase } from "./ResultsPanelImageGallery";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { getFallbackImages, getImages } from "../../../components/media/mxts-image-gallery/mxts";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { ConfiguredLink } from "../../../utils/linking.util";
import { ContentType } from "../../../components/components.enum";
import { DomainObjectUtil } from "../../../utils/domainobject.util";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import ProgressBar from "../../../components/ProgressBar";
import { WidgetOptions } from "./";
import { cancelable } from "../../../promise";

export interface ResultsPanelImageGalleryContainerProps {
    accommodationType?: AccommodationType;
    imageManagerId: number;
    imageManagerIds?: number[];
    context: CMSProviderProperties;
    unit?: Unit;
    dynamicFilter?: DynamicFilter;
    specialClass: string;
    options: WidgetOptions;
    amenityCodes?: string[];
    resort?: MXTS.Resort;
    className?: string;
    link: ConfiguredLink;
    warmupState?: ResultsPanelImageGalleryContainerState;
    activity?: Activity;
}

export interface ResultsPanelImageGalleryContainerState {
    isLoading: boolean;
    images: MXTS.Image[];
    fallbackImages: MXTS.Image[];
}

export class ResultsPanelImageGalleryContainer extends React.Component<ResultsPanelImageGalleryContainerProps, ResultsPanelImageGalleryContainerState> {
    private cancelFetchingImages?: () => void;
    private cancelFetchingFallbackImages?: () => void;

    public static async warmupCache(props: ResultsPanelImageGalleryContainerProps): Promise<ResultsPanelImageGalleryContainerState> {
        return warmupState(props, ResultsPanelImageGalleryContainer.defaultState(props), async (stateHandler) => {
            await ResultsPanelImageGalleryContainer.setImages(props, stateHandler);
            const { images, fallbackImages } = stateHandler.state;
            await ResultsPanelImageGalleryBase.warmupCache({
                ...props,
                images: props.unit ? (images.length > 0 ? images : fallbackImages) : images,
                availabilityState: props.context.reduxStore.store.getState().availabilityState,
            });
        });
    }

    private static defaultState(props: ResultsPanelImageGalleryContainerProps): ResultsPanelImageGalleryContainerState {
        return {
            isLoading: true,
            images: [],
            fallbackImages: [],
        };
    }

    constructor(props: ResultsPanelImageGalleryContainerProps) {
        super(props);
        this.state = {
            ...ResultsPanelImageGalleryContainer.defaultState(props),
            ...(props.warmupState || {}),
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ResultsPanelImageGalleryContainerProps) {
        if (this.props.imageManagerId !== nextProps.imageManagerId) {
            ResultsPanelImageGalleryContainer.setImages(nextProps, this, this);
        }
    }

    public componentDidMount() {
        ResultsPanelImageGalleryContainer.setImages(this.props, this, this);
    }

    public shouldComponentUpdate(nextProps: ResultsPanelImageGalleryContainerProps, nextState: ResultsPanelImageGalleryContainerState): boolean {
        return this.state.isLoading !== nextState.isLoading || nextProps.specialClass !== this.props.specialClass;
    }

    public componentWillUnmount() {
        this.cancelFetchingFallbackImages?.();
        this.cancelFetchingImages?.();
    }

    public render(): JSX.Element | null {
        const { accommodationType, options, context, className, specialClass, unit, dynamicFilter, amenityCodes, resort, link, activity } = this.props;
        const { images, fallbackImages, isLoading } = this.state;
        if (isLoading) {
            return <ProgressBar isImage={true} />;
        }
        return (
            <ErrorBoundary>
                <ResultsPanelImageGallery
                    accommodationType={accommodationType}
                    images={unit || activity ? (images.length > 0 ? images : fallbackImages) : images}
                    specialClass={specialClass}
                    unit={unit}
                    activity={activity}
                    dynamicFilter={dynamicFilter}
                    options={options}
                    context={context}
                    amenityCodes={amenityCodes}
                    resort={resort}
                    className={className}
                    link={link}
                />
            </ErrorBoundary>
        );
    }

    private static async getResortImageManagerId(props: ResultsPanelImageGalleryContainerProps): Promise<number | undefined> {
        const { context, accommodationType } = props;
        const env = await getMxtsEnv(context, context.currentLocale.code);
        const resortId = accommodationType?.resortId;
        if (resortId) {
            const resort = await DomainObjectUtil.getResort(context.mxtsApi, { resortIds: [resortId] }, env);
            return resort?.imageManagerId;
        }
    }

    private static async setImages(
        props: ResultsPanelImageGalleryContainerProps,
        stateHandler: StateHandler<ResultsPanelImageGalleryContainerProps, ResultsPanelImageGalleryContainerState>,
        tthis?: ResultsPanelImageGalleryContainer
    ): Promise<void> {
        try {
            const { context, imageManagerId, imageManagerIds, unit, options, activity } = props;
            if (imageManagerId) {
                tthis?.cancelFetchingImages?.();
                let fetchImages;
                let cancelFetchingImages;
                let resortImageManagerId: number | undefined;
                if (options.contentTypes?.some((contentType) => contentType.value === ContentType.RESORT)) {
                    resortImageManagerId = await ResultsPanelImageGalleryContainer.getResortImageManagerId(props);
                }
                if (imageManagerIds?.length) {
                    [fetchImages, cancelFetchingImages] = cancelable(getFallbackImages(context, imageManagerIds, context.currentLocale.code));
                } else {
                    [fetchImages, cancelFetchingImages] = cancelable(getImages(context, resortImageManagerId || imageManagerId, undefined, context.currentLocale.code));
                }
                if (tthis) {
                    tthis.cancelFetchingImages = cancelFetchingImages;
                }
                const urls = await fetchImages;
                if (urls.length > 0) {
                    stateHandler.setState({ isLoading: false, images: urls });
                } else if (unit) {
                    const env = await getMxtsEnv(context, context.currentLocale.code);
                    const resource = await context.mxtsApi.resources(env, { size: 1, resourceIds: [unit.resourceId] }).then((res: MXTS.PagedResult<MXTS.Resource>) => res.content);
                    await this.getFallbackType(resource, props, stateHandler, tthis);
                } else if (activity) {
                    // Fallback activity images
                    const urls = await getImages(
                        context,
                        activity.showMainActivity ? activity.resourceActivity.imageManagerId : activity.resourceActivity.resortActivity.imageManagerId,
                        undefined,
                        context.currentLocale.code
                    );
                    stateHandler.setState({ isLoading: false, fallbackImages: urls });
                }
            } else {
                // For stopping the infinte Progrees bar loading
                stateHandler.setState({ isLoading: false });
            }
        } catch (e) {
            stateHandler.setState({ isLoading: false });
            console.log(e);
        }
    }

    private static async getFallbackType(
        resource: MXTS.Resource[],
        props: ResultsPanelImageGalleryContainerProps,
        stateHandler: StateHandler<ResultsPanelImageGalleryContainerProps, ResultsPanelImageGalleryContainerState>,
        tthis?: ResultsPanelImageGalleryContainer
    ): Promise<void> {
        const { context } = props;
        const fallbackUnitImageManagerId = resource[0].imageManagerId;
        tthis?.cancelFetchingFallbackImages?.();
        const [fetchFallbackImages, cancelFetchingFallbackImages] = cancelable(getImages(context, fallbackUnitImageManagerId, undefined, context.currentLocale.code));
        if (tthis) {
            tthis.cancelFetchingFallbackImages = cancelFetchingFallbackImages;
        }
        try {
            const urls = await fetchFallbackImages;
            stateHandler.setState({ isLoading: false, fallbackImages: urls });
        } catch (e) {
            stateHandler.setState({ isLoading: false });
            console.log(e);
        }
    }
}
