import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { AccommodationType, Resort, Unit } from "../../../plugins/mxts";
import { Button, CardImg, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { Image, PagedResult, WithAddressResult } from "@maxxton/cms-mxts-api";
import { LocalizedOptions, WidgetOptions } from "./";
import ReactIdSwiper, { ReactIdSwiperProps } from "react-id-swiper";
import { Site, WithId } from "@maxxton/cms-api";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { Activity } from "../../page/activityPlanner/activityPlanner.types";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import LocalizedTitleAndLabel from "../../../components/widgetTitleAndLabel/LocalizedLableTitle";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { connect } from "react-redux";
import { forceUpdateSwiper } from "../../page/image-gallery/imageGallery.utils";
import { getEnv } from "../../../utils/env.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import loadable from "@loadable/component";
import { loadableRetry } from "../../../utils/loadableComponents.util";
import namespaceList from "../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../components/utils";
import { setPageViewEvent } from "../resultsPanelUtil";

const Swiper = loadable(() => loadableRetry(() => import("react-id-swiper")), {
    resolveComponent: (Swiper) => Swiper.default as React.ForwardRefExoticComponent<any>,
});
export interface ResultsPanelImageGalleryBaseProps {
    images: Image[];
    accommodationType?: AccommodationType;
    specialClass: string;
    resort?: Resort;
    unit?: Unit;
    options: WidgetOptions;
    context: CMSProvidedProperties;
    amenityCodes?: string[];
    className?: string;
    link: ConfiguredLink;
    dynamicFilter?: DynamicFilter;
    activity?: Activity;
}

interface ResultsPanelImageGalleryBaseStoreProps {
    availabilityState: AvailabilityState;
}

export interface ResultsPanelImageGalleryProps extends ResultsPanelImageGalleryBaseProps, ResultsPanelImageGalleryBaseStoreProps {}

export interface ResultsPanelImageGalleryState {
    openModal: boolean;
    slidePosition: number;
    selectedImage: any | null;
    site?: Site & WithId;
    loadedImages: Array<{ image: Image; visibility: boolean }>;
    positionTracker: number;
    resortWithAdress?: WithAddressResult<PagedResult<Resort>>;
}

export class ResultsPanelImageGalleryBase extends React.Component<ResultsPanelImageGalleryProps, ResultsPanelImageGalleryState> {
    public static async warmupCache(props: ResultsPanelImageGalleryProps): Promise<ResultsPanelImageGalleryState> {
        return warmupState(props, ResultsPanelImageGalleryBase.defaultState(props), async (stateHandler) => {
            if (!props.unit?.city) {
                await ResultsPanelImageGalleryBase.getResortWithAdress(stateHandler);
            }
        });
    }

    private static defaultState(props: ResultsPanelImageGalleryProps): ResultsPanelImageGalleryState {
        return {
            openModal: false,
            slidePosition: 0,
            selectedImage: null,
            loadedImages: [],
            positionTracker: 0,
        };
    }

    // Swiper instance
    private swiper: typeof ReactIdSwiper = React.createRef();
    private thumbnailSwiper: typeof ReactIdSwiper = React.createRef();
    private imageCounter: number;
    private position: number;
    private slideWidth: number;
    private item: any;
    private items: any;

    private insideSlidePosition: number;
    private thumbnailSlidePosition: any;
    private insideSlideItem: any;
    private insideSlideItems: any;
    private thumbnailSlideItem: any;
    private thumbnailSlideItems: any;

    private readonly FALLBACK_IMAGE_URL = "https://newyse-res.cloudinary.com/image/upload/t_mcms_larger/f_auto/image-fallback.jpg";
    private fallbackImage = (<CardImg loading="lazy" top width="100%" src={this.FALLBACK_IMAGE_URL} className="img-fluid fallback-image" />);

    constructor(props: ResultsPanelImageGalleryProps) {
        super(props);
        this.state = {
            ...ResultsPanelImageGalleryBase.defaultState(props),
        };
        this.thumbnailSlidePosition = 0;
        this.insideSlidePosition = 0;
        this.position = 0;
        this.slideWidth = 0;
        this.imageCounter = 0;
    }

    public componentDidMount() {
        const { images, unit } = this.props;
        let loadedImages: Array<{ image: Image; visibility: boolean }> = [];
        images.forEach((image, index) => {
            if (index === 0 || index === 1 || index === images.length - 1) {
                const visibility = true;
                loadedImages = [...loadedImages, { visibility, image }];
            } else {
                const visibility = false;
                loadedImages = [...loadedImages, { visibility, image }];
            }
        });
        this.setState({ loadedImages });
        // added resort location as a fallback location if unit is present and unit has no location
        if (!unit?.city) {
            ResultsPanelImageGalleryBase.getResortWithAdress(this);
        }
        forceUpdateSwiper(this.swiper);
    }

    private static async getResortWithAdress(stateHandler: StateHandler<ResultsPanelImageGalleryProps, ResultsPanelImageGalleryState>) {
        const { availabilityState, unit, context } = stateHandler.props;
        const env = await getEnv({ availabilityState, context });
        if (unit?.resortId) {
            const resort = await context.mxtsApi.resortsWithAddress(env, { size: 15, resortIds: [unit?.resortId!] });
            stateHandler.setState({ resortWithAdress: resort });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const { images, options, context, className, accommodationType, unit, dynamicFilter, amenityCodes, resort, specialClass, link } = this.props;
        const { selectedImage, resortWithAdress, slidePosition } = this.state;
        const { currentLocale, site } = context;
        const hideWidget = setOpacityOnHide(options);
        const media360: boolean = images.some((image) => image.mediaType === "VIDEO360");
        const iconClassName = options.showOptionalIconText && options.optionalIcon ? <FontAwesome name={options.optionalIcon} className={`fontawesome-${options.optionalIcon}`} /> : null;
        const localContent = options && options.localized ? options.localized.find((lc) => lc.locale === context.currentLocale.locale) : null;
        const localizedWidgetTitle: string = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedWidgetTitle || [], keys: ["widgetTitleText"] })?.widgetTitleText || "";
        const titleOptions = {
            localizedTitle: localizedWidgetTitle,
            enableWidgetTitle: options.enableWidgetTitle,
            useTitleHeadings: options.useTitleHeadings,
            styleWidgetTitle: options.styleWidgetTitle,
            className: classNames("widget-heading", `${options.fontColor?.includes("theme") ? `color-${options.fontColor}` : ""}`),
            style: options.fontColor?.includes("rgba") ? options.fontColor : "",
        };
        const sliderParams: ReactIdSwiperProps = {
            slidesPerView: 1,
            loop: true,
            lazy: true,
            preloadImages: false,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        };
        if (options.showSliderInsideImageIndicators) {
            sliderParams.pagination = {
                el: ".swiper-pagination",
                clickable: true,
                dynamicBullets: true,
                renderBullet: (index, className) => `<span class="${className} swiper-pagination-bullet"></span>`,
                bulletClass: "__size",
            };
        }
        const sliderParamsModal: ReactIdSwiperProps = {
            loop: true,
            preloadImages: false,
            lazy: true,
            spaceBetween: 10,
            loopedSlides: 1,
            slidesPerView: 1,
            activeSlideKey: slidePosition.toString(),
            shouldSwiperUpdate: true,
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        };

        const thumbnailParams = {
            spaceBetween: 10,
            centeredSlides: true,
            slidesPerView: 5,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            lazy: true,
            preloadImages: false,
            loop: true,
            loopedSlides: 4,
            shouldSwiperUpdate: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        };
        const gallerySwiper = this.swiper?.current?.swiper;
        const thumbnailSwiper = this.thumbnailSwiper?.current?.swiper;

        if (gallerySwiper?.controller && thumbnailSwiper?.controller) {
            gallerySwiper.controller.control = thumbnailSwiper;
            thumbnailSwiper.controller.control = gallerySwiper;
        }
        const modalPopup = (
            <Modal isOpen={this.state.openModal} toggle={this.handleModal.bind(this, slidePosition)} size="md" className="page-modal">
                <div className="card-object__content">
                    <ModalHeader tag="div" toggle={this.handleModal.bind(this, slidePosition)} className="modal_filters">
                        <h2 className="h5-text space-mb-0 font-700">
                            {options.showModalTitle && localContent && localContent.modalTitle
                                ? localContent.modalTitle
                                : accommodationType
                                ? accommodationType.name
                                : unit
                                ? unit.name
                                : resort
                                ? resort.name
                                : ""}
                        </h2>
                    </ModalHeader>
                    <div className="card-object__location">
                        <span className="card-object__location__item resort">{accommodationType ? accommodationType.resortName : unit ? unit.resortName : resort ? resort.name : ""}</span>
                        <span className="card-object__location__item space-mb-0 city">
                            <FontAwesome name="map-marker" /> {accommodationType ? accommodationType.city : unit?.city || resortWithAdress?.addresses[0]?.city || resort?.city}
                        </span>
                    </div>
                </div>
                <ModalBody>
                    <section className="container-fluid filter-wrapper popup-slider types-popup-wrapper">
                        <div className="slider-image image-scroller">
                            <div className="slideshow-container image-scroller-items" ref={(input) => (this.items = input)}>
                                <Swiper {...sliderParamsModal} className="slideShow-wrapper" ref={this.swiper}>
                                    {images.map(
                                        (image: Image, index: number) =>
                                            image && (
                                                <div
                                                    key={index}
                                                    className="card"
                                                    ref={(input) => {
                                                        this.item = input;
                                                    }}
                                                >
                                                    <CardImg
                                                        loading="lazy"
                                                        top
                                                        src={!media360 ? this.getImageUrl(image.urls?.large) : image.thumbnailUrl}
                                                        className="swiper-lazy modal-slide"
                                                        alt={
                                                            image.caption
                                                                ? image.caption
                                                                : image.name
                                                                ? image.name
                                                                : accommodationType
                                                                ? accommodationType.name
                                                                : unit
                                                                ? unit.name
                                                                : resort
                                                                ? resort.name
                                                                : ""
                                                        }
                                                    />
                                                    {options.showFigCaption && <figcaption>{image.caption ? image.caption.split("-").join(" ") : image.name ? image.name : ""}</figcaption>}
                                                </div>
                                            )
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    </section>
                </ModalBody>
                {options.showThumbnailOnModal && (
                    <ModalFooter>
                        <section className="container-fluid filter-wrapper popup-slider popup-thumbnail-slider types-popup-wrapper">
                            <div className="image-scroller">
                                <div className="image-scroller-items" ref={(input) => (this.thumbnailSlideItems = input)}>
                                    <Swiper {...thumbnailParams} ref={this.thumbnailSwiper}>
                                        {images.map(
                                            (image: Image, index: number) =>
                                                image && (
                                                    <div
                                                        key={index}
                                                        className="card"
                                                        ref={(input) => {
                                                            this.item = input;
                                                        }}
                                                    >
                                                        <CardImg
                                                            loading="lazy"
                                                            top
                                                            src={!media360 ? this.getImageUrl(image.urls?.large) : image.thumbnailUrl}
                                                            className="swiper-lazy"
                                                            alt={
                                                                image.caption
                                                                    ? image.caption
                                                                    : image.name
                                                                    ? image.name
                                                                    : accommodationType
                                                                    ? accommodationType.name
                                                                    : unit
                                                                    ? unit.name
                                                                    : resort
                                                                    ? resort.name
                                                                    : ""
                                                            }
                                                        />
                                                        {options.showFigCaption && <figcaption>{image.caption ? image.caption.split("-").join(" ") : image.name ? image.name : ""}</figcaption>}
                                                    </div>
                                                )
                                        )}
                                    </Swiper>
                                </div>
                            </div>
                        </section>
                    </ModalFooter>
                )}
            </Modal>
        );

        let galleryReveal = "";
        let divClassFirst = "";
        let divClassSecond = "";
        if (options.placementOfMultipleImages === "left") {
            galleryReveal = "left-reveal";
            divClassFirst = "col-md-8 p-0";
            divClassSecond = "col-md-4 px-1";
        } else if (options.placementOfMultipleImages === "top") {
            galleryReveal = "top-reveal";
            divClassFirst = "col-md-12 pl-1 pr-0";
            divClassSecond = "d-flex pl-1";
        } else if (options.placementOfMultipleImages === "bottom") {
            galleryReveal = "bottom-reveal";
            divClassFirst = "col-md-12 pl-1 pr-0 mb-1";
            divClassSecond = "d-flex pl-1";
        } else {
            galleryReveal = "right-reveal";
            divClassFirst = "col-md-8 px-1";
            divClassSecond = "col-md-4 px-0";
        }
        if (options.imageGalleryType === "multiple") {
            return images && images.length > 0 ? (
                // eslint-disable-next-line max-len
                <div className={`multi-image-gallery ${hideWidget} ${galleryReveal}`}>
                    <LocalizedTitleAndLabel {...titleOptions} />
                    <div className={classNames("row layout--xxs image-gallery-widget no-gutters", { ["apply-hover"]: !options.removeHoverEffect })}>
                        {(galleryReveal === "left-reveal" || galleryReveal === "top-reveal") && (
                            <div>
                                {this.getMultipleImageDiv(divClassSecond)}
                                {this.getSingleImageDiv(divClassFirst)}
                            </div>
                        )}
                        {
                            // eslint-disable-next-line max-len
                            (galleryReveal === "right-reveal" || galleryReveal === "bottom-reveal") && (
                                <div>
                                    {this.getSingleImageDiv(divClassFirst)}
                                    {this.getMultipleImageDiv(divClassSecond)}
                                </div>
                            )
                        }
                    </div>
                    {options.imageActionType === "showPopup" && modalPopup}
                </div>
            ) : images === undefined || images.length === 0 ? (
                <figure className="space-mb-0">{this.fallbackImage}</figure>
            ) : null;
        }
        const bookUrl = UrlParamsUtil.getBookingsEngineUrl(context.currentLocale.code, unit, accommodationType, link.url, dynamicFilter, amenityCodes);
        const anchorUrl: ConfiguredLink = { anchor: link.anchor, url: bookUrl || "" };
        const wrapperFunction = (index: number) => {
            this.handleModal(index);
            setPageViewEvent(context, bookUrl, accommodationType, unit);
        };
        const { loadedImages } = this.state;
        return images && images.length > 0 && loadedImages.length > 0 ? (
            <div className={`${galleryReveal} ${hideWidget} ${options.useAsBackground ? "bg-img-wrap" : ""}`}>
                <LocalizedTitleAndLabel {...titleOptions} />
                {media360 ? (
                    <div className="video360">
                        <img loading="lazy" src="https://newyse-res.cloudinary.com/image/upload/v1561112433/video360.svg" />
                    </div>
                ) : (
                    ""
                )}
                <div className={classNames("single-img-slider", className)}>
                    {
                        // We will create a different component later on for this single image slider
                    }
                    {options.showSliderInsideImage && (
                        <div key={unit ? unit.unitId : accommodationType ? accommodationType.resourceId : resort ? resort.resortId : images.length} className="inline-img-slider-wrap">
                            {!options.hideSpecialLabel &&
                                accommodationType?.specials?.map((special) => (
                                    <span key={special.resourceId} className={specialClass}>
                                        {special.name}
                                    </span>
                                ))}
                            <section className="container-fluid filter-wrapper inline-img-slider types-popup-wrapper">
                                <div className="slider-image image-scroller">
                                    <div className="slideshow-container image-scroller-items" ref={(input) => (this.insideSlideItems = input)}>
                                        <Swiper {...sliderParams} className="slideShow-wrapper" ref={this.swiper}>
                                            {loadedImages.map(
                                                (loadImage: any, index: number) =>
                                                    loadImage && (
                                                        <SmartLink
                                                            key={index}
                                                            // eslint-disable-next-line max-len
                                                            {...(options.imageActionType === "redirectLink" ? { href: getUrlWithAnchor(anchorUrl) } : {})}
                                                            {...(options.imageActionType === "redirectLink" ? { target: link.target } : {})}
                                                            // eslint-disable-next-line max-len
                                                            className={`${
                                                                options.imageActionType === "showPopup" ? "open-modal" : options.imageActionType === "redirectLink" ? "cursor-pointer" : "no-modal"
                                                            }`}
                                                            onClick={() => wrapperFunction(index)}
                                                        >
                                                            {
                                                                <div
                                                                    // eslint-disable-next-line max-len
                                                                    className={`card slide-content ${options.useAsBackground ? "bg-img" : ""}`}
                                                                    ref={(input) => {
                                                                        this.insideSlideItem = input;
                                                                    }}
                                                                    style={
                                                                        options.useAsBackground
                                                                            ? {
                                                                                  backgroundImage:
                                                                                      "url(" +
                                                                                      (loadImage.image.mediaType !== "VIDEO360"
                                                                                          ? this.getImageUrl(loadImage.image.urls?.medium)
                                                                                          : loadImage.image.thumbnailUrl) +
                                                                                      ")",
                                                                              }
                                                                            : undefined
                                                                    }
                                                                >
                                                                    {options.useAsBackground ? null : (
                                                                        <CardImg
                                                                            loading="lazy"
                                                                            top
                                                                            srcSet={
                                                                                loadImage.image.mediaType !== "VIDEO360"
                                                                                    ? `${this.getImageUrl(loadImage.image.urls?.small)} 320w, ${this.getImageUrl(
                                                                                          loadImage.image.urls?.medium
                                                                                      )} 800w, ${this.getImageUrl(loadImage.image.urls?.large)} 1200w`
                                                                                    : `${loadImage.image.thumbnailUrl} 320w`
                                                                            }
                                                                            src={
                                                                                loadImage.image.mediaType !== "VIDEO360"
                                                                                    ? // Changing it t medium to reduce data usage
                                                                                      // large was using too much data and
                                                                                      // the calls were slower as well
                                                                                      // eslint-disable-next-line max-len
                                                                                      this.getImageUrl(loadImage.image.urls?.medium)
                                                                                    : loadImage.image.thumbnailUrl
                                                                            }
                                                                            alt={loadImage.image.caption || loadImage.image.name || accommodationType?.name || unit?.name || resort?.name}
                                                                        />
                                                                    )}
                                                                    {options.imageActionType === "showPopup" &&
                                                                        options.showOptionalIconText &&
                                                                        (options.optionalIcon || (localContent && localContent.optionalText !== "")) && (
                                                                            <span className="image-overlay">
                                                                                {iconClassName}
                                                                                <span>{localContent && localContent.optionalText !== "" && localContent.optionalText}</span>
                                                                            </span>
                                                                        )}

                                                                    {options.showFigCaption && <figcaption>{loadImage.image.caption?.split("-").join(" ") || loadImage.image.name || ""}</figcaption>}
                                                                    {options.imageActionType === "showPopup" &&
                                                                        options.showOptionalIconText &&
                                                                        (options.optionalIcon || (localContent && localContent.optionalText !== "")) && (
                                                                            <span className="image-overlay">
                                                                                {iconClassName}
                                                                                {localContent?.optionalText && <span>{localContent.optionalText}</span>}
                                                                            </span>
                                                                        )}
                                                                </div>
                                                            }
                                                        </SmartLink>
                                                    )
                                            )}
                                        </Swiper>
                                    </div>
                                </div>
                            </section>
                        </div>
                    )}
                    {!options.showSliderInsideImage && options.imageActionType === "showPopup" && <a onClick={this.handleModal.bind(this, 0)}>{this.singleImageCard()}</a>}
                    {!options.showSliderInsideImage && !(options.imageActionType === "showPopup") && this.singleImageCard()}
                </div>
                {options.imageActionType === "showPopup" && modalPopup}
            </div>
        ) : images === undefined || images.length === 0 ? (
            <figure className="space-mb-0">{this.fallbackImage}</figure>
        ) : null;
    }
    private getSingleImageDiv = (divClassFirst: string) => {
        const { options, context, specialClass, accommodationType, unit, dynamicFilter, images, amenityCodes, resort, link } = this.props;
        const { currentLocale, site } = context;
        const localContent: LocalizedOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localized || [] });
        const bookUrl = UrlParamsUtil.getBookingsEngineUrl(context.currentLocale.code, unit, accommodationType, link.url, dynamicFilter, amenityCodes);
        const anchorUrl: ConfiguredLink = {
            url: "",
        };
        anchorUrl.anchor = link.anchor;
        anchorUrl.url = bookUrl;
        const wrapperFunction = () => {
            this.handleModal(0);
            setPageViewEvent(context, bookUrl, accommodationType, unit);
        };

        return (
            <div className={`${divClassFirst} main-image layout__item`}>
                {options.imageActionType === "noAction" ? (
                    <div onClick={this.handleModal.bind(this, 0)}>
                        <figure className="space-mb-0">
                            {specialClass &&
                                accommodationType?.specials?.map((special) => (
                                    <span key={special.resourceId} className={specialClass}>
                                        {special.name}
                                    </span>
                                ))}
                            {images && images[0] ? (
                                <CardImg
                                    loading="lazy"
                                    top
                                    width="100%"
                                    src={images[0].mediaType !== "VIDEO360" ? this.getImageUrl(images[0].urls?.large) : images[0] ? images[0].thumbnailUrl : undefined}
                                    alt={images[0].caption || images[0].name || accommodationType?.name || unit?.name || resort?.name}
                                />
                            ) : (
                                this.fallbackImage
                            )}
                            {options.showFigCaption && <figcaption>{images[0].caption ? images[0].caption.split("-").join(" ") : images[0].name ? images[0].name : ""}</figcaption>}
                        </figure>
                    </div>
                ) : (
                    <SmartLink
                        {...(options.imageActionType === "redirectLink" ? { href: getUrlWithAnchor(anchorUrl) } : {})}
                        // eslint-disable-next-line max-len
                        {...(options.imageActionType === "redirectLink" ? { target: link.target } : {})}
                        // eslint-disable-next-line max-len
                        className={`${options.imageActionType === "showPopup" ? "open-modal" : options.imageActionType === "redirectLink" ? "cursor-pointer" : "no-modal"}`}
                        onClick={wrapperFunction}
                    >
                        <figure className="space-mb-0">
                            {specialClass &&
                                accommodationType?.specials?.map((special) => (
                                    <span key={special.resourceId} className={specialClass}>
                                        {special.name}
                                    </span>
                                ))}
                            {images && images[0] ? (
                                <CardImg
                                    loading="lazy"
                                    top
                                    width="100%"
                                    src={images[0].mediaType !== "VIDEO360" ? this.getImageUrl(images[0].urls?.large) : images[0].thumbnailUrl}
                                    alt={images[0].caption || images[0].name || accommodationType?.name || unit?.name || resort?.name}
                                />
                            ) : (
                                this.fallbackImage
                            )}
                            {options.showFigCaption && <figcaption>{images[0].caption ? images[0].caption.split("-").join(" ") : images[0].name ? images[0].name : ""}</figcaption>}
                        </figure>
                    </SmartLink>
                )}
            </div>
        );
    };

    // eslint-disable-next-line max-lines-per-function
    private getMultipleImageDiv = (divClassSecond: string) => {
        const { options, context, accommodationType, unit, dynamicFilter, images, amenityCodes, resort, link } = this.props;
        const { currentLocale, site } = context;
        const localContent: LocalizedOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localized || [] });
        const bookUrl = UrlParamsUtil.getBookingsEngineUrl(context.currentLocale.code, unit, accommodationType, link.url, dynamicFilter, amenityCodes);
        const anchorUrl: ConfiguredLink = {
            url: "",
        };
        anchorUrl.anchor = link.anchor;
        anchorUrl.url = bookUrl;
        const iconClassName = options.showOptionalIconText && options.optionalIcon ? <FontAwesome name={options.optionalIcon} className={`fontawesome-${options.optionalIcon}`} /> : null;
        return (
            <div className={`${divClassSecond} layout__item card-box-object__photos`}>
                {options.imageActionType === "noAction" ? (
                    <div onClick={this.handleModal.bind(this, 1)}>
                        <figure className="space-mb-xxs">
                            {images && images.length > 0 && images[1] ? (
                                <CardImg
                                    loading="lazy"
                                    top
                                    width="100%"
                                    src={images[1].mediaType !== "VIDEO360" ? this.getImageUrl(images[1].urls?.medium) : images[1].thumbnailUrl}
                                    alt={images[1].caption || images[1].name || accommodationType?.name || unit?.name || resort?.name}
                                />
                            ) : (
                                this.fallbackImage
                            )}
                            {options.showFigCaption && <figcaption>{images[1]?.caption ? images[1].caption.split("-").join(" ") : images[1]?.name ? images[1].name : ""}</figcaption>}
                        </figure>
                    </div>
                ) : (
                    <SmartLink
                        {...(options.imageActionType === "redirectLink" ? { href: getUrlWithAnchor(anchorUrl) } : {})}
                        // eslint-disable-next-line max-len
                        {...(options.imageActionType === "redirectLink" ? { target: link.target } : {})}
                        // eslint-disable-next-line max-len
                        className={`${options.imageActionType === "showPopup" ? "open-modal" : options.imageActionType === "redirectLink" ? "cursor-pointer" : "no-modal"}`}
                        onClick={this.handleModal.bind(this, 1)}
                    >
                        <figure className="space-mb-xxs">
                            {images && images.length > 0 && images[1] ? (
                                <CardImg
                                    loading="lazy"
                                    top
                                    width="100%"
                                    src={images[1] && images[1].mediaType !== "VIDEO360" ? this.getImageUrl(images[1].urls?.medium) : images[1] ? images[1].thumbnailUrl : undefined}
                                    alt={images[1].caption || images[1].name || accommodationType?.name || unit?.name || resort?.name}
                                />
                            ) : (
                                this.fallbackImage
                            )}
                            {options.showFigCaption && <figcaption>{images[1]?.caption ? images[1]?.caption.split("-").join(" ") : images[1]?.name ? images[1]?.name : ""}</figcaption>}
                        </figure>
                    </SmartLink>
                )}
                {options.imageActionType === "noAction" ? (
                    <div onClick={this.handleModal.bind(this, 2)}>
                        <figure className="space-mb-0">
                            {images && images.length > 0 && images[2] ? (
                                <CardImg
                                    loading="lazy"
                                    top
                                    width="100%"
                                    src={images[2].mediaType !== "VIDEO360" ? this.getImageUrl(images[2].urls?.medium) : images[2] ? images[2].thumbnailUrl : undefined}
                                    alt={images[2].caption || images[2].name || accommodationType?.name || unit?.name || resort?.name}
                                />
                            ) : (
                                this.fallbackImage
                            )}
                            {options.showFigCaption && images && images[2] && (
                                <figcaption>{images[2].caption ? images[2].caption.split("-").join(" ") : images[2].name ? images[2].name : ""}</figcaption>
                            )}
                            {options.showOptionalIconText && (options.optionalIcon || (localContent && localContent.optionalText !== "")) && (
                                <a className={classNames("image-overlay", { "image-overlay-bg": options?.displayButton, "d-none": options?.hideOverlay })}>
                                    <div className={classNames({ "d-none": options.displayButton })}>
                                        {iconClassName}
                                        <div>{localContent && localContent.optionalText !== "" && localContent.optionalText}</div>
                                    </div>
                                    <Button className={classNames("button", "show-photos", { "d-none": !options.displayButton })}>
                                        <FontAwesome name="picture-o" />
                                        <span>
                                            {localContent?.optionalButtonText ? localContent.optionalButtonText : getI18nLocaleString(namespaceList.admin, "showAllPhotos", currentLocale, site)}{" "}
                                        </span>
                                    </Button>
                                </a>
                            )}
                        </figure>
                    </div>
                ) : (
                    <SmartLink
                        {...(options.imageActionType === "redirectLink" ? { href: getUrlWithAnchor(anchorUrl) } : {})}
                        // eslint-disable-next-line max-len
                        {...(options.imageActionType === "redirectLink" ? { target: link.target } : {})}
                        // eslint-disable-next-line max-len
                        className={`${options.imageActionType === "showPopup" ? "open-modal" : options.imageActionType === "redirectLink" ? "cursor-pointer" : "no-modal"}`}
                        onClick={this.handleModal.bind(this, 2)}
                    >
                        <figure className="space-mb-0">
                            {images && images.length > 0 && images[2] ? (
                                <CardImg
                                    loading="lazy"
                                    top
                                    width="100%"
                                    src={images[2].mediaType !== "VIDEO360" ? this.getImageUrl(images[2].urls?.medium) : images[2] ? images[2].thumbnailUrl : undefined}
                                    alt={images[2].caption || images[2].name || accommodationType?.name || unit?.name || resort?.name}
                                />
                            ) : (
                                this.fallbackImage
                            )}
                            {options.showFigCaption && images && images[2] && (
                                <figcaption>{images[2].caption ? images[2].caption.split("-").join(" ") : images[2].name ? images[2].name : ""}</figcaption>
                            )}
                            {options.imageActionType === "showPopup" && options.showOptionalIconText && (options.optionalIcon || (localContent && localContent.optionalText !== "")) && (
                                <div className={classNames("image-overlay", { "image-overlay-bg": options?.displayButton, "d-none": options?.hideOverlay })}>
                                    <div className={classNames({ "d-none": options.displayButton })}>
                                        {iconClassName}
                                        <div>{localContent && localContent.optionalText !== "" && localContent.optionalText}</div>
                                    </div>
                                    <Button onClick={this.handleModal.bind(this, 1)} className={classNames("button", "show-photos", { "d-none": !options.displayButton })}>
                                        <FontAwesome name="picture-o" />
                                        <span>
                                            {localContent?.optionalButtonText ? localContent.optionalButtonText : getI18nLocaleString(namespaceList.admin, "showAllPhotos", currentLocale, site)}{" "}
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </figure>
                    </SmartLink>
                )}
            </div>
        );
    };

    private singleImageCard = () => {
        const {
            images,
            options,
            context: { currentLocale, site },
            specialClass,
            accommodationType,
            unit,
            resort,
        } = this.props;

        const localContent = getLocalizedContent({ currentLocale, site, localizedContent: options.localized });

        return (
            <figure
                className={`space-mb-0 ${options.useAsBackground ? "bg-img" : ""}`}
                style={options.useAsBackground ? { backgroundImage: "url(" + this.getImageUrl(images[0].urls?.large) + ")" } : undefined}
            >
                {specialClass &&
                    accommodationType?.specials?.map((special) => (
                        <span key={special.resourceId} className={specialClass}>
                            {special.name}
                        </span>
                    ))}
                {options.useAsBackground ? null : (
                    <CardImg
                        loading="lazy"
                        top
                        width="100%"
                        srcSet={`${this.getImageUrl(images[0].urls?.small)} 320w, ${this.getImageUrl(images[0].urls?.medium)} 800w, ${this.getImageUrl(images[0].urls?.large)} 1200w`}
                        src={this.getImageUrl(images[0].urls?.large)}
                        alt={images[0].caption ? images[0].caption : images[0].name ? images[0].name : accommodationType ? accommodationType.name : unit ? unit.name : resort ? resort.name : ""}
                    />
                )}
                {options.showFigCaption && <figcaption>{images[0].caption ? images[0].caption : images[0].name ? images[0].name : ""}</figcaption>}
                {options.imageActionType === "showPopup" && options.showOptionalIconText && options.optionalIcon && localContent && localContent.optionalText && (
                    <div className={classNames("image-overlay", { "image-overlay-bg": options?.displayButton, "d-none": options?.hideOverlay })}>
                        <div className={classNames({ "d-none": options.displayButton })}>
                            {options.optionalIcon && <FontAwesome name={options.optionalIcon} className={`fontawesome-${options.optionalIcon}`} />}
                            <div>{localContent && localContent.optionalText && <span>{localContent.optionalText}</span>}</div>
                        </div>
                        <Button onClick={this.handleModal.bind(this, 1)} className={classNames("button", "show-photos", { "d-none": !options.displayButton })}>
                            <FontAwesome name="picture-o" />
                            <span>{localContent?.optionalButtonText ? localContent.optionalButtonText : getI18nLocaleString(namespaceList.admin, "showAllPhotos", currentLocale, site)} </span>
                        </Button>
                    </div>
                )}
            </figure>
        );
    };

    private slideImage = (type: string, imgLength: number) => {
        const { options } = this.props;
        if (type === "modal") {
            this.items.style = "transition: all 0.7s ease-in-out;";
            this.imageSlider(this.position, this.item);
            this.setState({ slidePosition: this.position });
        } else if (type === "inSlider") {
            this.imageSlider(this.insideSlidePosition, this.insideSlideItem);
            this.setState({ slidePosition: this.insideSlidePosition });
        } else if (type === "thumbnailSlider") {
            const amountOfSlideshow = Math.ceil(imgLength / 6);
            if (this.thumbnailSlidePosition <= amountOfSlideshow) {
                this.imageSlider(this.thumbnailSlidePosition, this.thumbnailSlideItem);
                if (this.thumbnailSlidePosition === amountOfSlideshow) {
                    this.thumbnailSlidePosition = 0;
                    this.slideImage(type, imgLength);
                }
            }
        } else if (type === "indicatorSlider") {
            const amountOfSlideshow = Math.ceil(imgLength / (options.amountOfIndicators || 7));
            if (this.thumbnailSlidePosition <= amountOfSlideshow) {
                this.imageSlider(this.thumbnailSlidePosition, this.thumbnailSlideItem);
                if (this.thumbnailSlidePosition === amountOfSlideshow) {
                    this.thumbnailSlidePosition = 0;
                    this.slideImage(type, imgLength);
                }
            }
        }
    };
    private switchSlider = (position: number, image: any, transition: any) => {
        this.position = position;
        if (!this.items) {
            return;
        }
        this.items.style = transition === "no-transition" ? "transition: none;" : "transition: all 0.7s ease-in-out;";
        this.imageSlider(this.position, this.item);
        this.setState({ selectedImage: image, slidePosition: position });
    };

    private handleModal = (position: number) => {
        const { images } = this.props;
        this.position = position;
        this.thumbnailSlidePosition = 0;
        this.setState({ openModal: !this.state.openModal, slidePosition: this.position });
        setTimeout(() => {
            this.switchSlider(this.position, images[this.position], "no-transition");
        }, 10);
    };

    private imageSlider = (position: number, item: any) => {
        this.slideWidth = -position * item?.offsetWidth;
    };

    private getImageUrl(url?: string): string {
        const { options } = this.props;
        if (!url) {
            return this.FALLBACK_IMAGE_URL;
        }
        switch (options.dynamicImageSize) {
            case "large":
                if ((options.imageGalleryType === "single" && url.includes("normal")) || (options.imageGalleryType === "multiple" && url.includes("normal"))) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_normal", "t_mcms_larger_fill_ratio/f_auto");
                }
                if (url.includes("large")) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_large", "t_mcms_larger_fill_ratio/f_auto");
                }
                break;
            case "medium":
                if ((options.imageGalleryType === "single" && url.includes("normal")) || (options.imageGalleryType === "multiple" && url.includes("normal"))) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_normal", "t_mcms_medium_fill_ratio/f_auto");
                }
                if (url.includes("large")) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_large", "t_mcms_medium_fill_ratio/f_auto");
                }
                break;
            case "original":
                if ((options.imageGalleryType === "single" && url.includes("normal")) || (options.imageGalleryType === "multiple" && url.includes("normal"))) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_normal", "t_mcms_original/f_auto");
                }
                if (url.includes("large")) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_large", "t_mcms_original/f_auto");
                }
            case "small":
                if ((options.imageGalleryType === "single" && url.includes("normal")) || (options.imageGalleryType === "multiple" && url.includes("normal"))) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_normal", "t_mcms_small/f_auto");
                }
                if (url.includes("large")) {
                    return url.replace("t_newyse_", "t_mcms_").replace("t_mcms_large", "t_mcms_small/f_auto");
                }
                break;
            default:
                break;
        }
        return url
            .replace("t_newyse_", "t_mcms_")
            .replace("t_mcms_normal", "t_mcms_medium")
            .replace("t_mcms_medium", "t_mcms_medium_fill_ratio/f_auto")
            .replace("t_mcms_large", "t_mcms_larger_fill_ratio/f_auto")
            .replace("t_mcms_small", "t_mcms_small/f_auto");
    }
}

function mapStateToProps(state: State): ResultsPanelImageGalleryBaseStoreProps {
    return {
        availabilityState: state.availabilityState,
    };
}

export const ResultsPanelImageGallery = wrapProps<ResultsPanelImageGalleryBaseProps>(connect<ResultsPanelImageGalleryBaseStoreProps>(mapStateToProps)(ResultsPanelImageGalleryBase));
